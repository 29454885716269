@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  }

  100% {
    opacity: 1;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

.animate-fade-in {
  animation: fade-in 3s ease-in-out;
  overflow: hidden;
}

@keyframes bounce {
  0% {
    left: 0px;
    top: 0px;
  }

  100% {
    left: 0px;
    top: 20px;
  }
}

.animate-bounce {
  animation: bounce 1s ease-in-out alternate infinite;
}

@keyframes zoom-in-out {

  0%,
  100% {
    transform: scale(1.2);
  }

  50% {
    transform: scale(1);
  }
}

.animate-zoom {
  animation: zoom-in-out 5s infinite;
}

@keyframes up {
  0% {
    left: 0px;
    top: -0.5rem;
  }

  100% {
    left: 0px;
    top: 1rem;
  }
}

.animate-up {
  animation: up 1.5s ease-in-out alternate infinite;
}

@keyframes down {
  0% {
    left: 0px;
    top: 4.5rem;
  }

  100% {
    left: 0px;
    top: 3rem;
  }
}

.animate-down {
  animation: down 1.5s ease-in-out alternate infinite;
}

@keyframes up-phone {
  0% {
    left: 0px;
    top: 0rem;
  }

  100% {
    left: 0px;
    top: 0.5rem;
  }
}

.animate-up-phone {
  animation: up-phone 1.2s ease-in-out alternate infinite;
}

@keyframes down-phone {
  0% {
    left: 0px;
    top: 2rem;
  }

  100% {
    left: 0px;
    top: 1.5rem;
  }
}

.animate-down-phone {
  animation: down-phone 1.2s ease-in-out alternate infinite;
}

.scroll-button {
  padding-bottom: 2px;
  position: relative;
  border-bottom: 6px solid transparent;
  transition: 1s all ease-in-out;
}

.scroll-button.active::before {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 50%;
  width: 50%;
  height: 4px;
  background-color: #A82CF6;
  transform: translateX(-50%);
  border-radius: 2px;
}

.scrollbar-none {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

img {
  pointer-events: none;
}